<template>
  <b-container fluid class="p-0 dashboard-page">
    <container-header />
      <b-row class="d-flex justify-content-center">
        <b-col sm="11" md="7" lg="7" xl="5">
          <get-number-screen></get-number-screen>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import ContainerHeader from '@/components/App/UI/ContainerHeader.vue';
import GetNumberScreen from '@/components/App/User/GetNumber/GetNumberScreen.vue';

export default {
  components: {
    ContainerHeader,
    GetNumberScreen,
  },
};
</script>
